import Checkbox from "antd-mobile/es/components/checkbox";
import List from "antd-mobile/es/components/list";
import Popover from "antd-mobile/es/components/popover";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import convert from "convert";
import Fraction from "fraction.js";
import { CheckCircle2, ShoppingCart } from "lucide-react";
import { useCallback, useMemo, useRef } from "react";
import { usePantrySelectorContext } from "~/components/context/pantryContext";
import { useShoppingListSelectorContext } from "~/components/context/shoppingListContext";
import { convertUnits, shortPluralUnits, shortUnits } from "~/utils/db/recipe-ingredient-parser-v2/units";
import clsx from "~/utils/shared/ClassesUtils";


interface IngredientProps {
  ingredient: {
    quantity: number;
    unit: string;
    ingredient: string;
    comment: string;
  };
  leftActions: any[];
  rightActions: any[];
}

export default function Ingredient({ ingredient, leftActions, rightActions }: IngredientProps) {
  const quantityFraction = new Fraction(ingredient.quantity).toFraction(true);
  const pantryItems = usePantrySelectorContext((state) => state.pantryItems);

  const shoppingListItems = useShoppingListSelectorContext((state) => state.shoppingListItems);

  const handleClickPantry = () => {
    console.log("clicked pantry");
  };

  const inPantry = useMemo(() => {
    const found = pantryItems.find((p) => p.ingredient === ingredient.ingredient);
    if (found) {
      let compareUnit = ingredient.unit;
      let converted;
      try {
        converted = convert(found.quantity, found.unit).to(compareUnit);
      } catch (e) {
        if (found.unit === ingredient.unit) {
          converted = found.quantity;
        }
      }
      let formattedQty = new Fraction(converted).toFraction(true);

      const pantryEnough = converted >= ingredient.quantity;

      let unit = found.unit;
      if (convertUnits[unit]) {
        const lookup = convertUnits[unit];
        unit = converted > 1 ? shortPluralUnits[lookup] : shortUnits[lookup];
      } else if (shortUnits[unit]) {
        unit = converted > 1 ? shortPluralUnits[unit] : shortUnits[unit];
      } else if (unit === "undefined") {
        unit = null;
      }
      // return (
      //   <div className="flex items-center gap-1 text-sm" onClick={handleClickPantry}>
      //     {pantryEnough && <CheckEmptyCircle className="h-4 w-4 text-theme-400" />}
      //     {`In Pantry: `}
      //     {formattedQty}®
      //     {` ${unit}`}
      //   </div>
      // );

      return (
        //TODO:: add a badge with the number of items in the pantry
        <>{pantryEnough ? <Popover
          content='Should have enough in your pantry'
          trigger='click'
          mode="dark"
        ><CheckCircle2 className={clsx("h6 w-6 text-theme-600")} /></Popover> : null}</>
      );
    }
    return null;
  }, [ingredient, pantryItems]);

  const onShoppingList = useMemo(() => {
    const found = shoppingListItems.find((p) => p.ingredient === ingredient.ingredient);
    if (found) {
      let compareUnit = ingredient.unit;
      let converted;
      try {
        converted = convert(found.quantity, found.unit).to(compareUnit);
      } catch (e) {
        if (found.unit === ingredient.unit) {
          converted = found.quantity;
        }
      }
      if (converted > 0) {
        let formattedQty = new Fraction(converted).toFraction(true);
        return <Popover
          content='In your shopping list'
          trigger='click'
          mode="dark"
        ><ShoppingCart className="h-6 w-6 text-orange-700" /></Popover>;
      }
      return null;
    }
    return null;
  }, [ingredient, pantryItems]);

  const ingredientRef = useRef();
  let quantity;
  if (ingredient.quantity > 0) {
    quantity = quantityFraction;
    if (quantity.split(" ").length > 0) {
      //need to wrap the second item in a span with a class
      const split = quantity.split(" ");
      //if either is a fraction, wrap it in a span with a class
      quantity = split.map((s, index) => {
        if (s.includes("/")) {
          return (
            <span key={index} className="diagonal-fractions">
              {s}
            </span>
          );
        }
        return s;
      });
    }
  }
  //TODO: format the unit (small, large, plural)
  const unit = ingredient.unit ?? null;
  const ingredientName = ingredient.ingredient;
  const all = [unit, ingredientName].filter((n) => n).join(" ");

  const handleActionsRevealed = useCallback(
    (direction: "left" | "right") => {
      if (direction === "left") {
        if (leftActions.length === 1) {
          leftActions[0].onClick && leftActions[0].onClick();
        }
      } else {
        if (rightActions.length === 1) {
          rightActions[0].onClick && rightActions[0].onClick();
        }
      }
      ingredientRef.current?.close();
    },
    [leftActions, rightActions]
  );

  return (
    <SwipeAction leftActions={leftActions} rightActions={rightActions} ref={ingredientRef} onActionsReveal={handleActionsRevealed}>
      <List.Item
        className="text-lg"
        description={ingredient.comment}
        extra={
          <div className="space-x-2">
            {inPantry}
            {onShoppingList}
          </div>
        }
      >
        <h4
        // onClick={() => {
        //   Toast.show("Swipe Left and Right to Add to Pantry or Shopping List");
        // }}
        >
          {quantity} {all}
        </h4>
      </List.Item>
    </SwipeAction>
  );
}

type IngredientCheckboxProps = {
  ingredient: {
    quantity: number;
    unit: string;
    ingredient: string;
    comment: string;
    id: string;
  };
  leftActions: any[];
  rightActions: any[];
  extra?: any;
  onChange?: (checked: boolean) => void;
};

export function IngredientCheckbox({ ingredient, leftActions = [], rightActions = [], extra, onChange }: IngredientCheckboxProps) {
  const checkBoxRef = useRef();
  const ingredientRef = useRef();
  const quantityFraction = new Fraction(ingredient.quantity).toFraction(true);
  let quantity;
  if (ingredient.quantity > 0) {
    quantity = quantityFraction;
    if (quantity.split(" ").length > 0) {
      //need to wrap the second item in a span with a class
      const split = quantity.split(" ");
      //if either is a fraction, wrap it in a span with a class
      quantity = split.map((s, index) => {
        if (s.includes("/")) {
          return (
            <span key={index} className="diagonal-fractions">
              {s}
            </span>
          );
        }
        return s;
      });
    }
  }
  //TODO: format the unit (small, large, plural)
  const unit = ingredient.unit ?? null;
  const ingredientName = ingredient.ingredient;
  const all = [unit, ingredientName].filter((n) => n).join(" ");

  const onClick = () => {
    checkBoxRef.current?.toggle();
  };

  const handleActionsRevealed = useCallback(
    (direction: "left" | "right") => {
      if (direction === "left") {
        if (leftActions.length === 1) {
          leftActions[0].onClick && leftActions[0].onClick();
        }
      } else {
        if (rightActions.length === 1) {
          rightActions[0].onClick && rightActions[0].onClick();
        }
      }
      ingredientRef.current?.close();
    },
    [leftActions, rightActions]
  );

  const handleChange = (value) => {
    onChange && onChange(value, ingredient.id);
  };

  const content = (
    <List.Item
      prefix={
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox value={ingredient.id} ref={checkBoxRef} onChange={handleChange} />
        </div>
      }
      className="text-lg"
      description={ingredient.comment}
      onClick={onClick}
      arrow={false}
      extra={extra}
    >
      <h3>
        {quantity} {all}
      </h3>
    </List.Item>
  );

  if (leftActions.length === 0 && rightActions.length === 0) {
    return content;
  }

  return (
    <SwipeAction leftActions={leftActions} rightActions={rightActions} ref={ingredientRef} onActionsReveal={handleActionsRevealed}>
      {content}
    </SwipeAction>
  );
}
