export const units = {
  cup: ["c", "c.", "C", "Cups"],
  gallon: ["gal"],
  ounce: ["oz", "oz."],
  pint: ["pt", "pts", "pt."],
  pound: ["lb", "lb.", "lbs", "lbs.", "Lb", "Lbs"],
  quart: ["qt", "qt.", "qts", "qts."],
  tablespoon: ["tbs", "tbsp", "tbspn", "T", "T.", "Tablespoons", "Tablespoon"],
  teaspoon: ["tsp", "tspn", "t", "t."],
  gram: ["g", "g."],
  kilogram: ["kg", "kg.", "Kg", "Kg."],
  liter: ["l", "l.", "L", "L."],
  milligram: ["mg", "mg."],
  milliliter: ["ml", "ml.", "mL", "mL."],
  package: ["pkg", "pkgs"],
  stick: ["sticks"],
  piece: ["pcs", "pcs."],
  pinch: ["pinch"],
  small: ["Small"],
  medium: ["Medium"],
  large: ["large", "Large"],
} as { [key: string]: string[] };

export const pluralUnits = {
  cup: "cups",
  gallon: "gallons",
  ounce: "ounces",
  pint: "pints",
  pound: "pounds",
  quart: "quarts",
  tablespoon: "tablespoons",
  teaspoon: "teaspoons",
  gram: "grams",
  kilogram: "kilograms",
  liter: "liters",
  milligram: "milligrams",
  milliliter: "milliliters",
  clove: "cloves",
  bag: "bags",
  box: "boxes",
  pinch: "pinches",
  can: "cans",
  slice: "slices",
  piece: "pieces",
} as { [key: string]: string };

export const shortUnits = {
  cup: "cup",
  gallon: "gal",
  ounce: "oz",
  pint: "pnt",
  pound: "lb",
  quart: "qt",
  tablespoon: "tbsp",
  teaspoon: "tsp",
  gram: "g",
  kilogram: "kg",
  liter: "l",
  milligram: "mg",
  milliliter: "ml",
  clove: "clove",
  bag: "bag",
  box: "box",
  pinch: "pinch",
  can: "can",
  slice: "slice",
  piece: "piece",
  package: "pkg",
  stick: "stick",
  small: "sm",
  medium: "md",
  large: "lg",
} as { [key: string]: string };

export const shortPluralUnits = {
  cup: "cups",
  gallon: "gals",
  ounce: "oz",
  pint: "pints",
  pound: "lbs",
  quart: "quart",
  tablespoon: "tbsp",
  teaspoon: "tsp",
  gram: "g",
  kilogram: "kg",
  liter: "L",
  milligram: "mg",
  milliliter: "mL",
  clove: "clove",
  bag: "bags",
  box: "boxes",
  pinch: "pinches",
  can: "cans",
  slice: "slices",
  piece: "pieces",
  package: "pkgs",
  stick: "sticks",
  small: "sm",
  medium: "md",
  large: "lg",
} as { [key: string]: string };

export const unitsLong = {
  cup: ["c", "c.", "C", "Cups"],
  gallon: ["gal"],
  ounce: ["oz", "oz."],
  pint: ["pt", "pts", "pt."],
  pound: ["lb", "lb.", "lbs", "lbs.", "Lb", "Lbs"],
  quart: ["qt", "qt.", "qts", "qts."],
  tablespoon: ["tbs", "tbsp", "tbspn", "T", "T.", "Tablespoons", "Tablespoon"],
  teaspoon: ["tsp", "tspn", "t", "t."],
  gram: ["g", "g."],
  kilogram: ["kg", "kg.", "Kg", "Kg."],
  liter: ["l", "l.", "L", "L."],
  milligram: ["mg", "mg."],
  milliliter: ["ml", "ml.", "mL", "mL."],
  package: ["pkg", "pkgs"],
  stick: ["sticks"],
  piece: ["pcs", "pcs."],
  pinch: ["pinch"],
  small: ["Small"],
  medium: ["Medium"],
  large: ["large", "Large"],
} as { [key: string]: string[] };

export const convertUnits = {
  tsp: "teaspoon",
  tbsp: "tablespoon",
  "fl oz": "fluid ounce",
  cup: "cup",
  pt: "pint",
  qt: "quart",
  gal: "gallon",
  mg: "milligram",
  g: "gram",
  kg: "kilogram",
  mL: "milliliter",
  L: "liter",
  oz: "ounce",
  lb: "pound",
  C: "celsius",
  F: "fahrenheit",
};
